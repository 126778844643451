
import BottomNav from '../components/BottomNav.vue'

export default {
  components: { BottomNav },
  head() {
    return {
      bodyAttrs: {
        class: 'bg-black',
      },
      htmlAttrs: {
        class: 'bg-black',
      },
    }
  },
  mounted() {},
}
