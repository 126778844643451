
export default {
  head() {
    return {
      bodyAttrs: {
        class: 'h-100',
      },
      htmlAttrs: {
        class: 'h-screen bg-gray-50',
      },
    }
  },
}
