
export default {
  head() {
    return {
      bodyAttrs: {
        class: 'bg-black',
      },
      htmlAttrs: {
        class: 'h-screen bg-black',
      },
    }
  },
}
