export default async (context) => {
  const workbox = await window.$workbox

  if (!workbox) {
    console.debug("Workbox couldn't be loaded.")
    return
  }

  const checkForUpdates = async () => {
    try {
      console.debug('Checking for updates...')
      const registration =
        await window.navigator.serviceWorker.getRegistration()
      if (registration) {
        const response = await fetch('/api/version')
        if (!response.ok) {
          throw new Error('Failed to fetch server version')
        }
        const serverVersion = await response.text()
        const clientVersion = localStorage.getItem('cachedVersion')
        console.debug('Current version:', clientVersion)
        console.debug('Server version:', serverVersion)
        if (!clientVersion || serverVersion !== clientVersion) {
          console.debug('New version is available. Updating service worker...')
          localStorage.setItem('cachedVersion', serverVersion)
          registration.update()
          window.location.reload()
        } else {
          console.debug('The PWA is on the latest version.')
        }
      }
    } catch (error) {
      console.debug('Error checking for updates:', error)
    }
  }

  const checkInterval = setInterval(checkForUpdates, 60000) // 1 minute

  workbox.addEventListener('controlling', () => {
    console.debug('The PWA is on the latest version.')
    clearInterval(checkInterval)
  })

  window.addEventListener('focus', () => {
    checkForUpdates()
  })
}
